import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import { routes } from './routes.js'
import NProgress from '@/utils/nprogress'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 每次跳转路由，调用获取消息数接口
  if (localStorage.getItem('token') && localStorage.getItem('tokenKey') && to.path !== '/login' && to.path !== '/login/forget') {
    store.dispatch('getMessageNum')
  }
  NProgress.start()
  next()
})
router.afterEach(async (to, from) => {
  // 判断当前页面是否开启缓存，如果开启，则将当前页面的 name 信息存入 keep-alive 全局状态
  if (to.meta.cache) {
    let componentName = to.matched[to.matched.length - 1].components.default.name
    if (componentName) {
      store.commit('keepAliveAdd', componentName)
    } else {
      console.warn('该页面组件未设置组件名，会导致缓存失效，请检查')
    }
  }
  // 判断离开页面是否开启缓存，如果开启，则根据缓存规则判断是否需要清空 keep-alive 全局状态里离开页面的 name 信息
  if (from.meta.cache) {
    let componentName = from.matched[from.matched.length - 1].components.default.name
    // 通过 meta.cache 判断针对哪些页面进行缓存
    switch (typeof from.meta.cache) {
      case 'string':
        if (from.meta.cache != to.name) {
          store.commit('keepAliveRemove', componentName)
        }
        break
      case 'object':
        if (!from.meta.cache.includes(to.name)) {
          store.commit('keepAliveRemove', componentName)
        }
        break
    }
    // 如果进入的是 reload 页面，则也将离开页面的缓存清空
    if (to.name == 'reload') {
      store.commit('keepAliveRemove', componentName)
    }
  }
  NProgress.done()
})

export default router
