// 权限变量
export const Mixins = {
    data() {
        return {
            scoped: null,
            role_id:null,
            user_name:null,
            accountType:null
        }
    },
    created() {
        this.power(parseInt(localStorage.getItem("role_id")));
        this.role_id=parseInt(localStorage.getItem("role_id"));
        this.user_name=localStorage.getItem("user_name");
        this.accountType=localStorage.getItem("accountType")
    },
    methods: {
        power(id) {
            switch (id) {
                case 3:
                    this.scoped = 1;
                    break;
                case 4:
                    this.scoped = 2;
                    break;
                case 5:
                    this.scoped = 3;
                    break;
                default:
                    this.scoped = 4;
            }
        }
    }
}



