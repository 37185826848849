export default {
  // 2022-01-12 
  formatTime(timestamp) {
    if (timestamp) {
      const time = new Date(timestamp);
      const y = time.getFullYear(); //getFullYear方法以四位数字返回年份
      const M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
      const d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
      return y + "/" + M + "/" + d + " ";
    } else {
      return "";
    }
  },
  //   2022-01-12 11:03
  formatTimeLine(timestamp) {
    const date = new Date(timestamp);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
  },

  //方法
  formatFloat(value) {
    var value = Math.round(parseFloat(value) * 100) / 100;
    var s = value.toString().split(".");
    if (s.length == 1) {
      value = value.toString() + ".00";
      return value;
    }
    if (s.length > 1) {
      if (s[1].length < 2) {
        value = value.toString() + "0";
      }
      return value;
    }
  },
  // 百分比转小数
  formatToNum(percent) {
    let str = percent.replace("%", "");
    str = str / 100;
    return str;
  },
  // 小数转百分比
  formatToPercent(point){
    var percent = Number(point*100).toFixed(2);
    percent+="%";
    return percent;
  }

};