<template>
  <Transition name="nested">
    <div v-if="showHeaderWrapper" class="header-wrapper">
      <div class="header-wrapper-content inner">
        <span>当前展示的是系统体验版模拟数据，如果您想进一步了解系统功能&nbsp;&nbsp;&nbsp;</span>
        <el-button :color="color" @click="goChat" round> 欢迎在线咨询 </el-button>
      </div>
      <div class="header-wrapper-close">
        <el-button :color="color" circle>
          <el-icon :size="20" @click="onClose"><Close /></el-icon>
        </el-button>
      </div>
    </div>
  </Transition>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'
import { Close } from '@element-plus/icons-vue'

const goChat = () => {
  window.open('https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b9780200a95204c9cef1bb7b1609323c77eca3e767203951e00d914830fe3ede2969aa7081e24bfb88f1a061124cbb73cb1')
}
const color = '#2d65b9'
defineProps({
  showHeaderWrapper: {
    type: Boolean,
    default: false
  } // 当前的tag数组
})
const emits = defineEmits(['close'])

const onClose = () => {
  emits('close')
}
</script>
<style lang="less" scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}
.nested-leave-active {
  transition-delay: 0.2s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(-60px);
  opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
.header-wrapper {
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: v-bind(color);

  &-content {
    font-size: 14px;
    :deep(.el-button) {
      --el-button-border-color: #fff !important;
      --el-button-hover-border-color: #fff !important;
      --el-button-active-border-color: #fff !important;
    }
  }
  &-close {
    position: absolute;
    right: 30px;
    :deep(.el-button) {
      height: 32px;
      width: 32px;
      --el-button-border-color: v-bind(color) !important;
      --el-button-hover-border-color: v-bind(color) !important;
      --el-button-active-border-color: v-bind(color) !important;
    }
  }
}
</style>
