import store from '@/store/index'
import Home from '../views/Home/Home'
import { companyLogin } from '@/utils/Api'

/**
 * meta说明：
 *  参数cache：页面缓存
 *      设为true表示从任何页面进入该页面都缓存（app生命周期内永久缓存）；
 *      设为String，值为路由的path,表示从此path进入该页面缓存；
 *      设为Array，String数组，表示一些path，从这些path进入该页面缓存；
 */

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        meta: {
          requireAuth: true,
          title: '首页'
        },
        beforeEnter: async (to, from, next) => {
          // 体验版
          if (to.query?.v === '58723627fcebc230ab0d53ddf5f16e34') {
            await new Promise((resolve, reject) => {
              companyLogin({
                data: {
                  phone: process.env.VUE_APP_TRIAL_USER,
                  passwd: process.env.VUE_APP_TRIAL_PASSWORD,
                  noToken: true,
                  loginway: 'p'
                }
              })
                .then((res) => {
                  if (res.status == 0) {
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('tokenKey', res.tokenKey)
                    next('/login/register')
                  } else {
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('tokenKey', res.tokenKey)
                    localStorage.setItem('user_name', res.user_name)
                    localStorage.setItem('role_id', res.role_id)
                    localStorage.setItem('phone', res.phone)
                    if (res.role_id == 3) {
                      localStorage.setItem('accountType', res.accountType)
                    }
                  }
                  resolve()
                })
                .catch((err) => {
                  console.error(err)
                  next('/login')
                  reject()
                })
            })
            store.commit('enterTrial', { trialFromUrl: document.referrer })
            next('/')
          }
          // 未登录
          if (!localStorage.getItem('token') && to.path === '/') {
            next('/login')
          }
          return next()
        },
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            '../views/Index/Index.vue'
          )
      },
      {
        path: '/reciver/reciverList',
        name: 'reciverList',
        meta: {
          requireAuth: true,
          cache: ['reciverReciverDetail', 'reciverReciverEdit'],
          title: '灵工列表'
        },
        component: () =>
          import(
            /* webpackChunkName: "reciverList" */
            '../views/Reciver/RevciverList.vue'
          )
      },
      {
        path: '/reciver/ReciverEdit',
        name: 'reciverReciverEdit',
        meta: {
          requireAuth: true,
          title: '灵工'
        },
        component: () =>
          import(
            /* webpackChunkName: "ReciverEdit" */
            '../views/Reciver/EditRevciver.vue'
          )
      },

      {
        path: '/reciver/MoreReciverEdit',
        meta: {
          requireAuth: true,
          title: '灵工'
        },
        component: () =>
          import(
            /* webpackChunkName: "MoreReciverEdit" */
            '../views/Reciver/MoreDetail.vue'
          )
      },

      {
        path: '/reciver/reciverDetail',
        name: 'reciverReciverDetail',
        meta: {
          requireAuth: true,
          title: '灵工详情'
        },
        component: () =>
          import(
            /* webpackChunkName: "reciverDetail" */
            '../views/Reciver/ReciverDetail.vue'
          )
      },

      {
        path: '/reciver/moreReciverList',
        meta: {
          requireAuth: true,
          title: ''
        },
        component: () =>
          import(
            /* webpackChunkName: "moreReciverList" */
            '../views/Reciver/MoreAddReciver.vue'
          )
      },
      {
        path: '/person',
        meta: {
          requireAuth: true,
          title: '个人信息'
        },
        component: () =>
          import(
            /* webpackChunkName: "person" */
            '../views/Info/Personal.vue'
          )
      },

      {
        path: '/companInfo',
        meta: {
          requireAuth: true,
          title: '企业信息'
        },
        component: () =>
          import(
            /* webpackChunkName: "companInfo" */
            '../views/Info/CompanyInfo.vue'
          )
      },
      {
        path: '/news',
        meta: {
          requireAuth: true,
          title: '消息中心'
        },
        component: () =>
          import(
            /* webpackChunkName: "news" */
            '../views/Info/New.vue'
          )
      },
      {
        path: '/company/companyEdit',
        meta: {
          requireAuth: true,
          title: '企业信息'
        },
        component: () =>
          import(
            /* webpackChunkName: "companyEdit" */
            '../views/Company/EditCompany.vue'
          )
      },

      {
        path: '/company/companyUserEdit',
        meta: {
          requireAuth: true,
          title: '企业用户'
        },
        component: () =>
          import(
            /* webpackChunkName: "companyUserEdit" */
            '../views/Company/EditCompanyUser.vue'
          )
      },
      {
        path: '/company/companyList',
        meta: {
          requireAuth: true,
          title: '企业列表'
        },
        component: () =>
          import(
            /* webpackChunkName: "companyList" */
            '../views/Company/CompanyList.vue'
          )
      },

      {
        path: '/company/companyUser',
        meta: {
          requireAuth: true,
          title: '企业用户管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "companyUser" */
            '../views/Company/CompanyUser.vue'
          )
      },
      {
        path: '/task/taskList',
        name: 'taskList',
        meta: {
          requireAuth: true,
          title: '任务列表',
          cache: 'taskTaskDetail'
        },
        component: () =>
          import(
            /* webpackChunkName: "taskList" */
            '../views/Task/TaskList.vue'
          )
      },
      {
        path: '/task/esignList',
        name: 'esignList',
        meta: {
          requireAuth: true,
          title: '签约管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "esignList" */
            '../views/Task/EsignList.vue'
          )
      },

      {
        path: '/task/soleAppoint',
        name: 'taskSoleAppoint',
        meta: {
          requireAuth: true,
          title: '单条指派'
        },
        component: () =>
          import(
            /* webpackChunkName: "soleAppoint" */
            '../views/Task/SoleAppoint.vue'
          )
      },
      {
        path: '/task/editTask',
        meta: {
          requireAuth: true,
          title: '任务信息'
        },
        component: () =>
          import(
            /* webpackChunkName: "editTask" */
            '../views/Task/EditTask.vue'
          )
      },

      {
        path: '/task/taskDetail',
        name: 'taskTaskDetail',
        meta: {
          requireAuth: true,
          title: '任务接单详情',
          cache: 'taskSoleAppoint'
        },
        component: () =>
          import(
            /* webpackChunkName: "taskDetail" */
            '../views/Task/TaskDetail.vue'
          )
      },
      {
        path: '/task/industryList',
        meta: {
          requireAuth: true,
          title: '行业列表'
        },
        component: () =>
          import(
            /* webpackChunkName: "industryList" */
            '../views/Task/IndustryList.vue'
          )
      },
      {
        path: '/finance/tradeRecord',
        name: 'financeTradeRecord',
        meta: {
          requireAuth: true,
          title: '交易记录',
          cache: 'financeTradeInfo'
        },
        component: () =>
          import(
            /* webpackChunkName: "tradeRecord" */
            '../views/Finance/TradeRecord.vue'
          )
      },

      {
        path: '/finance/financeMoney',
        meta: {
          requireAuth: true,
          title: '充值管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "financeMoney" */
            '../views/Finance/FinanceMoney.vue'
          )
      },
      {
        path: '/finance/financeAddress',
        meta: {
          requireAuth: true,
          title: '邮寄地址管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "financeAddress" */
            '../views/Finance/FinanceAddress.vue'
          )
      },
      {
        path: '/finance/invoiceRecord',
        meta: {
          requireAuth: true,
          cache: ['financeFinanceRecord', 'financeFinanceHead'],
          title: '可开票金额'
        },
        component: () =>
          import(
            /* webpackChunkName: "invoiceRecord" */
            '../views/Finance/InvoiceRecord.vue'
          )
      },
      {
        path: '/finance/financeHead',
        name: 'financeFinanceHead',
        meta: {
          requireAuth: true,
          title: '发票抬头'
        },
        component: () =>
          import(
            /* webpackChunkName: "financeHead" */
            '../views/Finance/FinanceHead.vue'
          )
      },
      {
        path: '/finance/financeRecord',
        name: 'financeFinanceRecord',
        meta: {
          requireAuth: true,
          title: '开票记录'
        },
        component: () =>
          import(
            /* webpackChunkName: "financeRecord" */
            '../views/Finance/FinanceRecord.vue'
          )
      },
      {
        path: '/finance/tradeInfo',
        name: 'financeTradeInfo',
        meta: {
          requireAuth: true,
          title: '交易信息'
        },
        component: () =>
          import(
            /* webpackChunkName: "tradeInfo" */
            '../views/Finance/TradeInfo.vue'
          )
      },
      {
        path: '/finance/FinanceRecordDetial',

        meta: {
          requireAuth: true,
          title: '开票管理详情'
        },
        component: () =>
          import(
            /* webpackChunkName: "FinanceRecordDetial" */
            '../views/Finance/FinanceRecordDetial.vue'
          )
      },
      {
        path: '/finance/confirmBilling',
        name: 'financeConfirmBilling',
        meta: {
          requireAuth: true,
          title: '确定账单'
        },
        component: () =>
          import(
            /* webpackChunkName: "confirmBilling" */
            '../views/Finance/ConfirmBilling.vue'
          )
      },
      {
        path: '/system/role',
        meta: {
          requireAuth: true,
          title: '角色管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "role" */
            '../views/System/Role.vue'
          )
      },
      {
        path: '/system/managerList',
        meta: {
          requireAuth: true,
          title: '用户管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "managerList" */
            '../views/System/ManagerList.vue'
          )
      },
      {
        path: '/reload',
        meta: {
          name: 'reload',
          requireAuth: true,
          title: '刷新页面'
        },
        component: () => import('@/views/personal/ReloadPage.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue')
  },
  {
    path: '/login/register',
    name: 'register',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/Login/ContinueRegister.vue')
  },
  {
    path: '/login/forget',
    name: 'forget',
    meta: {
      title: '忘记密码'
    },
    component: () => import(/* webpackChunkName: "forget" */ '../views/Login/Forget.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'nofound',
    component: () => import(/* webpackChunkName: "404" */ '../components/404.vue')
  }
]

export { routes }
