export default {
  state: () => ({
    list: [] // 保活的路由
  }),
  mutations: {
    keepAliveAdd(state, value) {
      if (typeof value === 'string') {
        !state.list.includes(value) && state.list.push(value)
      } else {
        value.map((v) => {
          v && !state.list.includes(v) && state.list.push(v)
        })
      }
    },
    keepAliveRemove(state, value) {
      if (typeof value === 'string') {
        state.list = state.list.filter((v) => {
          return v !== value
        })
      } else {
        state.list = state.list.filter((v) => {
          return !value.includes(v)
        })
      }
    },
    keepAliveClean(state) {
      state.list = []
    }
  },
  actions: {},
  getters: {}
}
