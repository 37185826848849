<template>
  <div class="container">
    <div class="container-left">
      <div class="logo" @click="toHome"></div>
      <el-button v-if="isTrial" color="#2265f0" type="primary" @click="goBack" round>退出体验</el-button>
    </div>

    <div class="container-right">
      <el-button :title="isFullscreen ? '退出全屏' : '进入全屏'" color="#fff" link @click="toggle">
        <i :class="['iconfont', isFullscreen ? 'icon-suoxiao' : 'icon-quanping']" />
      </el-button>
      <!-- 刷新 -->
      <el-button title="刷新页面" color="#fff" link @click="refresh">
        <i class="iconfont icon-shuaxin"></i>
      </el-button>
      <!-- 返回首页 -->
      <el-button title="返回首页" color="#fff" link @click="toHome">
        <i class="iconfont icon-shouye" />
      </el-button>
      <!-- 消息通知 -->
      <el-badge v-if="role_id == 3 || role_id == 4 || role_id == 5" :value="num ? num : ''">
        <el-button title="消息通知" class="badge" color="#fff" link @click="toNews">
          <i class="iconfont icon-xiaoxi"></i>
        </el-button>
      </el-badge>
      <!-- 我的 -->
      <el-dropdown v-if="isLogin">
        <span class="container-right-name">
          {{ user_name }}&nbsp;<el-icon><CaretBottom /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu trigger="hover">
            <el-dropdown-item @click="toPerson"> 个人中心</el-dropdown-item>
            <el-dropdown-item v-if="role_id == 3" @click="toCompany"> 企业信息</el-dropdown-item>
            <el-dropdown-item :disabled="isTrial ? true : false" @click="changeP"> 修改密码</el-dropdown-item>
            <el-dropdown-item :disabled="isTrial ? true : false" @click="layout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs, computed } from 'vue'
import { Mixins } from '@/utils/Mixins'
import { CaretBottom } from '@element-plus/icons-vue'
import { useFullscreen } from '@vueuse/core'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Head',
  mixins: [Mixins],
  components: {
    CaretBottom
  },
  setup() {
    const { isFullscreen, toggle } = useFullscreen()

    let store = useStore()
    const isTrial = computed(() => Number(store.state.trial.isTrial))
    const state = reactive({
      role_id: null,
      isLogin: localStorage.getItem('token') ? true : false
    })
    onMounted(() => {})

    const router = useRouter()
    let toHome = () => {
      router.push('/')
    }
    let toCompany = () => {
      router.push('/companInfo')
    }
    let toPerson = () => {
      router.push('/person')
    }
    let layout = () => {
      if (isTrial.value) return
      localStorage.clear()
      router.push('/login')
    }
    let toNews = () => {
      router.push('/news')
    }
    let goBack = () => {
      store.commit('exitTrial')
      localStorage.clear()
      if (store.state.trial.trialFromUrl) {
        window.open(store.state.trial.trialFromUrl, '_self')
      } else {
        window.open(process.env.VUE_APP_TRIAL_DEFAULT_FROM_DOMAIN, '_self')
      }
    }
    const refresh = () => {
      router.push('/reload')
    }

    return {
      ...toRefs(state),
      num: computed(() => store.state.message.num),
      changeP: () => {
        if (isTrial.value) return
        return store.commit('changeP', true)
      }, // 使用mutation方法的函数
      toHome,
      toNews,
      layout,
      toPerson,
      toCompany,
      goBack,
      refresh,
      CaretBottom,
      isFullscreen,
      toggle,
      isTrial
    }
  }
}
</script>
<style lang="less" scoped>
@import '../common/style/mixin';
.fix-head-name {
  vertical-align: middle;
}
.container {
  height: 100%;
  width: 100%;
  .flex(space-between);
  &-left {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &-right {
    display: flex;
    align-items: center;
    column-gap: 20px;
    :deep(.el-button + .el-button) {
      margin-left: 0;
    }
    &-name {
      font-size: 14px;
    }
    .iconfont {
      font-size: 18px;
      &.icon-shouye {
        font-size: 19px;
      }
    }
    .badge {
      display: block;
    }
  }
  .logo {
    width: 160px;
    height: 40px;
    margin: 0 28px 0 40px;
    background-image: url('../static/home-logo.png');
    background-size: 100% 100%;
    cursor: pointer;
  }
}
</style>
