<template>
  <div class="app-main">
    <router-view></router-view>
  </div>
</template>
<style lang="less">
.app-main {
  width: 100vw;
  height: 100vh;
}
body {
  font-family: -apple-system, Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB, '\5B8B\4F53', sans-serif;
}
</style>
