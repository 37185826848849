<template>
  <div>
    <el-container v-loading="loading">
      <el-dialog v-model="dialogVisible" title="修改密码" width="30%" center :before-close="cancel" :close-on-click-modal="false" @closed="submitLoading = false">
        <div class="info">
          <el-form ref="Form" :model="form" label-width="80px">
            <el-form-item label="手机号:">
              <div class="phone">{{ form.phone }}</div>
            </el-form-item>
            <div class="send">
              <el-form-item label="验证码">
                <el-input clearable placeholder="请输入验证码" v-model="form.verifycode" maxlength="6"></el-input>
              </el-form-item>
              <el-button class="send-code" type="primary" v-if="check" @click="toSendCode">发送验证码</el-button>
              <el-button class="send-code" type="primary" disabled v-else>{{ second }}秒重新发送</el-button>
            </div>
            <el-form-item label="新密码">
              <el-input clearable placeholder="密码长度为8-16位" v-model="form.passwd" maxlength="16" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input clearable placeholder="请再次输入新密码" v-model="conformP" show-password maxlength="16"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
              <el-button @click="cancel" type="primary" plain>取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </el-container>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted, reactive, ref, toRefs, computed } from 'vue'
import { queryPersonInfo, sendCode, changePassword } from '../utils/Api'
import { ElMessage } from 'element-plus'
import md5 from 'js-md5'
export default {
  name: 'ChangePassword',
  setup() {
    const store = useStore()
    const Form = ref(null)
    const router = useRouter()
    const state = reactive({
      loading: false,
      second: 60,
      check: true,
      timer: null,
      conformP: '',
      form: {
        phone: '',
        verifycode: '',
        passwd: ''
      },
      submitLoading: false
    })
    onMounted(() => {
      initPerson()
    })
    let toSendCode = () => {
      state.loading = true
      sendCode({
        data: {
          phone: state.form.phone,
          type: 3
        }
      })
        .then((res) => {
          state.loading = false
          ElMessage({
            showClose: true,
            message: '发送成功',
            type: 'success'
          })
          state.check = false
          state.timer = setInterval(() => {
            state.second--
            if (state.second === 0) {
              state.second = 60
              state.check = true
              clearInterval(state.timer)
            }
          }, 1000)
        })
        .catch((err) => {
          state.loading = false
          console.log(err)
        })
    }
    let submit = () => {
      if (!state.form.verifycode) {
        ElMessage({
          showClose: true,
          message: '验证码不为空',
          type: 'error'
        })
        return false
      }
      if (state.form.verifycode.length != 6) {
        ElMessage({
          showClose: true,
          message: '验证码不正确',
          type: 'error'
        })
        return false
      }
      if (!state.form.passwd) {
        ElMessage({
          showClose: true,
          message: '新密码不为空',
          type: 'error'
        })
        return false
      }
      if (state.form.passwd.length < 8 || state.form.passwd.length > 16) {
        ElMessage({
          showClose: true,
          message: '新密码长度为8-16位',
          type: 'error'
        })
        return false
      }
      if (!state.conformP) {
        ElMessage({
          showClose: true,
          message: '确认密码不为空',
          type: 'error'
        })
        return false
      }
      if (state.conformP.length < 8 || state.conformP.length > 16) {
        ElMessage({
          showClose: true,
          message: '确认密码长度为8-16位',
          type: 'error'
        })
        return false
      }
      if (state.conformP != state.form.passwd) {
        ElMessage({
          showClose: true,
          message: '两次输入密码不一致',
          type: 'error'
        })
        return false
      }
      state.submitLoading = true
      changePassword({
        data: {
          phone: state.form.phone,
          verifycode: state.form.verifycode,
          passwd: md5(state.form.passwd)
        }
      })
        .then((res) => {
          ElMessage({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          cancel()
        })
        .catch((err) => {
          state.submitLoading = false
          console.log(err)
        })
    }

    let initPerson = () => {
      queryPersonInfo()
        .then((res) => {
          state.loading = false
          state.form.phone = res.phone
        })
        .catch((err) => {
          console.log(err)
          state.loading = false
        })
    }
    let cancel = () => {
      store.commit('changeP', false)
      ;(state.form.verifycode = ''), (state.form.passwd = ''), (state.conformP = '')
      clearInterval(state.timer)
      state.check = true
      state.second = 60
    }
    return {
      ...toRefs(state),
      Form,
      submit,
      initPerson,
      toSendCode,
      dialogVisible: computed(() => store.state.isShowP),
      cancel
    }
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/mixin';
.el-form-item {
  margin-top: 20px;
}
.phone {
  font-size: 18px;
  text-align: left;
}
.info {
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 10px 0px 1px;
  &-title {
    margin-bottom: 20px;
    padding-left: 90px;
    font-size: 22px;
    font-family: Microsoft YaHei, Microsoft YaHei-Normal;
    font-weight: Normal;
    color: #333;
  }
}
.link {
  width: 100%;
  height: 100px;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
  a {
    color: @themColor;
    text-align: center;
    text-decoration: underline;
  }
}

.el-header {
  background-color: @fc;
  height: 80px;
  line-height: 80px;
  box-shadow: 0px 0px 14px 0px rgba(186, 186, 186, 0.5);
}
.send {
  height: 60px;
  margin-top: -20px;
  display: flex;
  .el-form-item {
    width: calc(100% - 60px);
  }
  &-code {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.el-header {
  background-color: @fc;
  height: 80px;
  line-height: 80px;
}
.el-aside {
  color: var(--el-text-color-primary);
  background-color: @fc;
  width: 300px;
}
.el-main {
  box-sizing: border-box;
}
.el-container {
  background: #f8f8f8;
  overflow: hidden;
}
</style>
/
