<template>
  <div class="container">
    <el-scrollbar>
      <el-menu class="el-menu-vertical" :router="true" :collapse="isCollapse" :default-active="getDefaultActive()">
        <!-- <el-menu>
          <el-menu-item index="6">
            <el-icon><house /></el-icon>
            <span>首页</span>
          </el-menu-item>
        </el-menu> -->
        <el-sub-menu index="1" v-if="role_id == 1 || role_id == 3 || role_id == 4 || role_id == 6 || role_id == 7">
          <template #title>
            <el-icon><user /></el-icon>
            <span>灵工管理</span>
          </template>
          <el-menu-item index="/reciver/reciverList">
            <el-icon><set-up /></el-icon>
            <span>灵工列表</span>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2" v-if="role_id == 1 || (role_id == 3 && accountType == 1) || role_id == 4 || role_id == 6 || role_id == 7 || role_id == 5">
          <template #title>
            <el-icon><office-building /></el-icon>
            <span>企业方管理</span>
          </template>
          <el-menu-item index="/company/companyList" v-if="role_id != 3">
            <el-icon><school /></el-icon>
            <span>企业列表</span>
          </el-menu-item>
          <el-menu-item index="/company/companyUser" v-if="role_id != 1 && role_id != 4 && role_id != 6 && role_id != 7 && role_id != 5">
            <el-icon><user /></el-icon>
            <span>企业用户管理</span>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3" v-if="role_id != 5">
          <template #title>
            <el-icon><document-copy /></el-icon>
            <span>任务管理</span>
          </template>
          <el-menu-item index="/task/taskList" v-if="role_id == 3 || role_id == 4 || role_id == 6 || role_id == 7">
            <el-icon><document-checked /></el-icon>
            <span>任务列表</span>
          </el-menu-item>
          <el-menu-item index="/task/esignList" v-if="role_id == 3 || role_id == 4 || role_id == 6 || role_id == 7">
            <el-icon><Stamp /></el-icon>
            <span>签约管理</span>
          </el-menu-item>
          <el-menu-item index="/task/industryList" v-if="role_id == 1">
            <el-icon><data-line /></el-icon>
            <span>行业列表</span>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4" v-if="(role_id != 1 && role_id != 4) || role_id == 3">
          <template #title>
            <el-icon><coin /></el-icon>
            <span>财务管理</span>
          </template>
          <el-menu-item index="/finance/financeMoney" v-if="role_id == 3 || role_id == 5 || role_id == 6">
            <el-icon><scale-to-original /></el-icon>
            <span>充值管理</span>
          </el-menu-item>
          <el-menu-item index="/finance/tradeRecord" v-if="role_id == 3 || role_id == 5 || role_id == 6 || role_id == 7">
            <el-icon><document-checked /></el-icon>
            <span>交易记录</span>
          </el-menu-item>
          <el-menu-item index="/finance/financeRecord" v-if="(role_id != 3 && role_id == 5) || role_id == 6 || role_id == 7">
            <el-icon><postcard /></el-icon>
            <span>开票记录</span>
          </el-menu-item>
          <el-menu-item index="/finance/invoiceRecord" v-if="role_id == 3">
            <el-icon><tickets /></el-icon>
            <span>开票管理</span>
          </el-menu-item>
          <el-menu-item index="/finance/financeAddress" v-if="role_id == 3">
            <el-icon><location /></el-icon>
            <span>邮寄地址管理</span>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="5" v-if="role_id == 1">
          <template #title>
            <el-icon><setting /></el-icon>
            <span>系统设置</span>
          </template>
          <el-menu-item index="/system/role">
            <el-icon><setting /></el-icon>
            <span>角色管理</span>
          </el-menu-item>
          <el-menu-item index="/system/managerList">
            <el-icon><user /></el-icon>
            <span>用户管理</span>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-scrollbar>
    <div class="menu-action">
      <el-link class="collapse" :underline="false" @click="toggleCollapse">
        <el-icon size="18px">
          <Fold v-show="!isCollapse" />
          <Expand v-show="isCollapse" />
        </el-icon>
      </el-link>
    </div>
  </div>
</template>
<script>
const COLLAPSE_WIDTH = 1000

import { Postcard, Location, Coin, User, DocumentCopy, Setting, OfficeBuilding, DataLine, DocumentChecked, School, SetUp, ScaleToOriginal, Tickets, Expand, Fold, Stamp } from '@element-plus/icons-vue'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Container',
  components: {
    Location,
    Coin,
    User,
    DocumentCopy,
    Setting,
    OfficeBuilding,
    DataLine,
    DocumentChecked,
    School,
    SetUp,
    ScaleToOriginal,
    Tickets,
    Postcard,
    Expand,
    Fold,
    Stamp
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      role_id: null,
      accountType: null
    })

    const autoCollapse = (width) => {
      if (width < COLLAPSE_WIDTH) {
        isCollapse.value = true
      }
    }
    window.addEventListener('resize', function () {
      autoCollapse(document.documentElement.clientWidth)
    })

    onMounted(() => {
      state.role_id = localStorage.getItem('role_id')
      state.accountType = localStorage.getItem('accountType')
    })

    const getDefaultActive = () => {
      if (!route.path) {
        return ''
      }
      if (route.path === '/reciver/reciverDetail' || route.path === '/reciver/ReciverEdit') {
        return '/reciver/reciverList'
      }
      if (route.path === '/finance/tradeInfo') {
        return '/finance/tradeRecord'
      }
      if (route.path === '/task/taskDetail') {
        return '/task/taskList'
      }
      if (route.path === '/finance/financeRecord' || route.path === '/finance/financeHead' || route.path === '/finance/FinanceRecordDetial') {
        return '/finance/invoiceRecord'
      }
      return route.path
    }

    const defaultCollapse = document.documentElement.clientWidth < COLLAPSE_WIDTH ? true : false
    const isCollapse = ref(defaultCollapse)
    const toggleCollapse = () => {
      isCollapse.value = !isCollapse.value
    }

    return {
      ...toRefs(state),
      getDefaultActive,
      isCollapse,
      toggleCollapse
    }
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/mixin';
@collapse-height: 50px;
@menu-width: 235px;
.container {
  overflow: auto;
  margin-top: 20px;
  color: var(--el-text-color-primary);
  background-color: @fc;
  flex-shrink: 0;
  .el-menu-vertical {
    border: 0;
    &:not(.el-menu--collapse) {
      width: @menu-width;
    }
  }
  :deep(.el-scrollbar) {
    height: calc(100% - @collapse-height);
  }
  .menu-action {
    padding: 0 10px;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    height: @collapse-height;
    box-shadow: 0 1px 4px rgb(0 21 41 / 16%);
    &:deep(.el-link.collapse) {
      padding: 10px;
      &:hover {
        background-color: var(--el-menu-hover-bg-color);
      }
    }
  }
}
</style>
