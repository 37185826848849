import axios from 'axios'
import { ElMessage } from 'element-plus'
import Router from '@/router/index'

const instance = axios.create({
  timeout: 6000
})
//请求拦截处理
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
//返回拦截处理
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)
const request = async (params = {}, transcode, url, type = 'POST', config = {}) => {
  return new Promise((resolve, reject) => {
    let data = params.data || {}
    if (!data.noToken) {
      data.tokenKey = localStorage.getItem('tokenKey') || ''
      data.token = localStorage.getItem('token') || ''
    } else {
      delete data.noToken
    }
    instance
      .request({
        method: type || 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          ...config.header
        },
        data: {
          head: {
            transcode: transcode,
            type: 'w'
          },
          data: data
        },
        ...config,
        transformRequest: [
          (reqData) => {
            if (config.header && config.header['Content-Type'] === 'application/x-www-form-urlencoded') {
              // 请求体格式为form-data
              let formData = new FormData()
              if (Object.prototype.hasOwnProperty.call(config.header, 'Content-Type') && config.header['Content-Type'] === 'application/x-www-form-urlencoded') {
                formData.append('tokenKey', localStorage.getItem('tokenKey') || '') // 登陆Ticket
                formData.append('token', localStorage.getItem('token') || '') // 登陆Ticket的解密key
                formData.append('transcode', transcode) // 交易码
                formData.append('type', 'w') // 终端类型
                for (let key in params) {
                  formData.append(key, params[key])
                }
              }
              return formData
            }
            return JSON.stringify(reqData)
          }
        ]
      })
      .then((res) => {
        if (type === 'GET' || type === 'get') {
          resolve(res)
          return false
        }
        if (typeof res.data.code == 'undefined' && res.data.returnCode == 'AAAAAAA') {
          resolve(res.data)
          return false
        }
        if (res.data.returnCode == '0000002') {
          ElMessage({
            showClose: true,
            message: '登录失效,请重新登录',
            type: 'error'
          })
          localStorage.clear()
          Router.push({
            path: '/login'
          })
          reject(res)
          return false
        }
        console.error(res)
        ElMessage({
          showClose: true,
          message: res.data.returnMsg ? res.data.returnMsg : `出现未知异常，请联系运维人员`,
          type: 'error'
        })
        // state.loading = false;
        reject(res.data)
        return false
      })
      .catch((error) => {
        ElMessage({
          showClose: true,
          message: error.message,
          type: 'error'
        })
        // ElMessage({
        //   showClose: true,
        //   message:'网络错误',
        //   type: 'error',
        // })
        // Router.push({path: '/login'})
        reject(error)
      })
  })
}
export default request
