<template>
  <div>
    <el-container style="height: 100vh">
      <HeaderWrapper :showHeaderWrapper="openHeaderWrapper ? true : false" @close="onHeaderWrapperClose" />
      <el-header class="page-header">
        <Head />
      </el-header>
      <el-container>
        <Container />
        <el-scrollbar class="page-main">
          <el-main>
            <router-view v-slot="{ Component, route }">
              <transition name="main" mode="out-in" appear>
                <keep-alive :include="keepAliveList">
                  <component :is="Component" :key="route.fullPath" />
                </keep-alive>
              </transition>
            </router-view>
            <ChangePassword />
          </el-main>
        </el-scrollbar>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { reactive, toRefs, computed } from 'vue'
import Head from '@/components/Head.vue'
import Container from '@/components/Container.vue'
import ChangePassword from '@/components/ChangePassword.vue'
import { Mixins } from '@/utils/Mixins'
import { useStore } from 'vuex'
import HeaderWrapper from '@/components/HeaderWrapper.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  Mixins: [Mixins],
  components: {
    Head,
    Container,
    ChangePassword,
    HeaderWrapper
  },
  setup() {
    const state = reactive({
      dialogVisible: true
    })
    const router = useRouter()
    let layout = () => {
      router.push('/login')
    }
    const onHeaderWrapperClose = () => {
      store.commit('closeHeaderWrapper')
    }
    const store = useStore()
    return {
      openHeaderWrapper: computed(() => Number(store.state.trial.openHeaderWrapper)),
      keepAliveList: computed(() => store.state.keepAlive.list),
      ...toRefs(state),
      layout,
      onHeaderWrapperClose
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/mixin';
.page {
  &-main {
    display: block;
    box-sizing: border-box;
    flex: 1;
    flex-basis: auto;
  }
  &-header {
    position: relative;
    z-index: 1;
    background-color: @fc;
    height: 80px;
    line-height: 80px;
    box-shadow: 0px 0px 14px 0px rgba(186, 186, 186, 0.5);
  }
}

.el-aside {
  color: var(--el-text-color-primary);
  background-color: @fc;
}
.el-container {
  background: #f8f8f8;
  overflow: hidden;
}

// 主内容区动画
.main-enter-active {
  transition: 0.2s;
}
.main-leave-active {
  transition: 0.15s;
}
.main-enter-from {
  opacity: 0;
  margin-left: -20px;
}
.main-leave-to {
  opacity: 0;
  margin-left: 20px;
}
</style>
