import { queryNews } from '@/utils/Api'
export default {
  state: () => ({
    num: JSON.parse(localStorage.msg_num ? localStorage.msg_num : '0') || null
  }),
  mutations: {
    changeMessageNum(state, value) {
      state.num = value
      localStorage.setItem('msg_num', value)
    }
  },
  actions: {
    getMessageNum({ commit }) {
      queryNews({
        data: {
          page: 1,
          num: 5,
          read_flag: 0
        }
      })
        .then((res) => {
          commit('changeMessageNum', res.unread_num)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  getters: {}
}
