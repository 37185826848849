export default {
  state: () => ({
    isTrial: localStorage.is_trial || '0',
    openHeaderWrapper: localStorage.open_header_wrapper || '0',
    trialFromUrl: localStorage.trial_from_url || ''
  }),
  mutations: {
    closeHeaderWrapper(state) {
      state.openHeaderWrapper = '0'
      localStorage.removeItem('open_header_wrapper')
    },
    enterTrial(state, data) {
      state.isTrial = '1'
      state.openHeaderWrapper = '1'
      state.trialFromUrl = data.trialFromUrl
      localStorage.setItem('is_trial', '1')
      localStorage.setItem('open_header_wrapper', '1')
      localStorage.setItem('trial_from_url', data.trialFromUrl)
    },
    exitTrial(state) {
      state.isTrial = '0'
      state.openHeaderWrapper = '0'
      localStorage.removeItem('is_trial')
      localStorage.removeItem('open_header_wrapper')
      localStorage.removeItem('trial_from_url')
    }
  },
  actions: {},
  getters: {}
}
