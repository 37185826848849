export default {
  state: () => ({
    collapse: false // 折叠左侧栏
  }),
  mutations: {
    changeCollapse(state) {
      state.collapse = !state.collapse
    }
  },
  actions: {},
  getters: {}
}
