/**
 * 配置文件 重要信息,Key等...
 */

// export const HTTP_URL = 'http://140.207.3.237:7090'   // 外网测试
// export const HTTP_URL = 'http://192.168.1.251:7090'   // 局域网测试

// export const HTTP_URL = 'https://fwp.51jrq.com'   // 线上

// export const HTTP_URL = '/api'    // api代理(本地)
export const HTTP_URL = process.env.VUE_APP_BASE_API
