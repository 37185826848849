import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import './assets/iconfont/iconfont.css'
import './utils/flexible'
import filters from './utils/filters'

import 'nprogress/nprogress.css' // nprogress样式

import '@/common/style/globals.less'

const app = createApp(App)

app.config.globalProperties.$filters = filters

app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: zhCn
})
app.mount('#app')
