import Axios from './Axios'
import * as config from './Config'

// 编辑接单方信息
export const editpuser = config.HTTP_URL + '/fwp/puser/editpuser'

// 编辑接单方信息(批量)
export const editpuserAll = config.HTTP_URL + '/fwp/puser/editimppuser'

// 上传凭证接口
export const upFileAddress = config.HTTP_URL + '/fwp/trade/recharge'

// 上传任务场景
export const upTaskImg = config.HTTP_URL + '/fwp/task/edittask'

// 上传完税证明
export const upProve = config.HTTP_URL + '/fwp/trade/settle'

// 上传发票凭证

export const upFinance = config.HTTP_URL + '/fwp/company/uploadinvoice'

// 上传企业信息(edit)
export const editCompany = config.HTTP_URL + '/fwp/company/editcompany'

// 完善企业信息
export const continueRegister = config.HTTP_URL + '/fwp/api/regcompany'

// 批量导入接单方
export const expertAll = config.HTTP_URL + '/fwp/puser/batchimport'

// 批量导入指派
export const expertAppoint = config.HTTP_URL + '/fwp/task/batchassign'

// 查询行业列表
export const queryIndustry = (params) => Axios(params, 'C0002', config.HTTP_URL + '/fwp/task/industrytype')

// 编辑行业列表
export const editIndustry = (params) => Axios(params, 'C0010', config.HTTP_URL + '/fwp/task/editindustry')

// 查询角色接口
export const queryRoles = (params) => Axios(params, 'C0012', config.HTTP_URL + '/fwp/api/roles')

// 编辑角色接口
export const editRoles = (params) => Axios(params, 'C0011', config.HTTP_URL + '/fwp/api/editrole')

// 查询用户管理
export const queryUsers = (params) => Axios(params, 'C0013', config.HTTP_URL + '/fwp/api/users')

// 删除用户管理
export const delUsers = (params) => Axios(params, 'C0015', config.HTTP_URL + '/fwp/api/deluser')

// 新增或者编辑用户接口
export const editUsers = (params) => Axios(params, 'C0014', config.HTTP_URL + '/fwp/api/edituser')
// 查询邮寄地址初始化
export const queryAddress = (params) => Axios(params, 'C0019', config.HTTP_URL + '/fwp/company/expresses')

// 设置默认地址
export const setAddress = (params) => Axios(params, 'C0021', config.HTTP_URL + '/fwp/company/setexpress')

// 删除默认地址
export const delAddress = (params) => Axios(params, 'C0022', config.HTTP_URL + '/fwp/company/delexpress')

// 增加或者修改邮寄地址
export const changeAddress = (params) => Axios(params, 'C0020', config.HTTP_URL + '/fwp/company/editexpress')

// 查询企业发票抬头
export const queryInvoice = (params) => Axios(params, 'C0016', config.HTTP_URL + '/fwp/company/invoiceinfo')

// 编辑企业发票抬头
export const editInvoice = (params) => Axios(params, 'C0018', config.HTTP_URL + '/fwp/company/editinvoice')

// 查询企业用户充值管理记录
export const queryCompanyRecharges = (params) => Axios(params, 'C0023', config.HTTP_URL + '/fwp/trade/recharges')

// 新增企业用户充值管理记录
export const addCompanyRecharges = (params) => Axios(params, 'C0024', config.HTTP_URL + '/fwp/trade/recharge')

// 企业用户管理设为主账号
export const setMainAccount = (params) => Axios(params, 'C0017', config.HTTP_URL + '/fwp/api/setadmin')

// 查询个人信息
export const queryPersonInfo = (params) => Axios(params, 'P0010', config.HTTP_URL + '/fwp/person/personal')

// 查询企业信息
export const queryCompanyInfo = (params) => Axios(params, 'P0013', config.HTTP_URL + '/fwp/api/company')

// 编辑企业信息
export const editCompanyInfo = (params) => Axios(params, 'P0014', config.HTTP_URL + '/fwp/api/editcompany')

// 发送手机验证码
export const sendCode = (params) => Axios(params, 'C0001', config.HTTP_URL + '/fwp/api/sendVerificationCode')

// 修改密码功能的实现
export const changePassword = (params) => Axios(params, 'P0015', config.HTTP_URL + '/fwp/api/updpwd')

// 忘记密码
export const forgetPassword = (params) => Axios(params, 'P0011', config.HTTP_URL + '/fwp/api/forgetpwd')

// 查询消息中心列表
export const queryNews = (params) => Axios(params, 'P0016', config.HTTP_URL + '/fwp/api/messages')

// 通过该接口进行已读或批量已读消息操作。
export const upNews = (params) => Axios(params, 'P0017', config.HTTP_URL + '/fwp/api/updmsg')

// 通过该接口进行删除或批量已读消息操作。
export const delNews = (params) => Axios(params, 'P0018', config.HTTP_URL + '/fwp/api/delmsg')

// 查询企业列表
export const queryCompanyList = (params) => Axios(params, 'C0025', config.HTTP_URL + '/fwp/company/companylist')

// 查询企业列表详情
export const queryCompanyDetail = (params) => Axios(params, 'C0035', config.HTTP_URL + '/fwp/company/companydetail')

// 审核企业信息
export const checkCompanyInfo = (params) => Axios(params, 'C0027', config.HTTP_URL + '/fwp/company/auditcompany')

// 删除企业信息
export const delCompanyInfo = (params) => Axios(params, 'C0028', config.HTTP_URL + '/fwp/company/delcompany')

//验证码登录
export const companyLogin = (params) => Axios(params, 'P0001', config.HTTP_URL + '/fwp/api/login')

// 任务列表
export const queryTaskList = (params) => Axios(params, 'C0003', config.HTTP_URL + '/fwp/task/tasklist')

// 删除当前任务
export const delTaskInfo = (params) => Axios(params, 'C0031', config.HTTP_URL + '/fwp/task/deltask')

// 通过id查询任务详情
export const queryTaskDetail = (params) => Axios(params, 'C0004', config.HTTP_URL + '/fwp/task/taskdetail')

// 审核任务是否通过接口
export const checekTask = (params) => Axios(params, 'C0030', config.HTTP_URL + '/fwp/task/audittask')

// 查询企业交易记录接口
export const queryTradeRecord = (params) => Axios(params, 'C0038', config.HTTP_URL + '/fwp/trade/trades')

// 查询及交易详情接口
export const queryTradeDetail = (params) => Axios(params, 'C0039', config.HTTP_URL + '/fwp/trade/tradeinfo')

// 查询交易接单方列表
export const queryTradePusers = (params) => Axios(params, 'C0053', config.HTTP_URL + '/fwp/trade/pusers')

// 导出功能
export const expertTradePusers = (params) => Axios(params, 'C0050', config.HTTP_URL + '/fwp/trade/exportpusers')

// 查询可开票金额(invoiceRecord页面)的开票记录
export const querytTrades = (params) => Axios(params, 'C0038', config.HTTP_URL + '/fwp/trade/trades')

// 查询(financeRecord页面)
export const querytInvoices = (params) => Axios(params, 'C0032', config.HTTP_URL + '/fwp/company/invoices')

// 填写快递信息(公司,单号)
export const writeInvoice = (params) => Axios(params, 'C0037', config.HTTP_URL + '/fwp/company/expressinvoice')

// 查群企业开票管理详情
export const queryInvoiceDetail = (params) => Axios(params, 'C0033', config.HTTP_URL + '/fwp/company/invoice')

// 通过该接口进行汇款失败反馈
export const toFeedback = (params) => Axios(params, 'C0054', config.HTTP_URL + '/fwp/trade/feedback')

// 企业注册功能
export const toCompanyRegister = (params) => Axios(params, 'P0002', config.HTTP_URL + '/fwp/api/register')

// 查询企业信息
export const queryCompanyInfoDetail = (params) => Axios(params, 'P0013', config.HTTP_URL + '/fwp/api/company')

// 查询企业信息
export const queryPusers = (params) => Axios(params, 'C0052', config.HTTP_URL + '/fwp/task/pusers')

// 查询接单方列表
export const queryReciverList = (params) => Axios(params, 'C0041', config.HTTP_URL + '/fwp/puser/pusers')

// 查询银行卡信息
export const queryBankInfo = (params) => Axios(params, 'C0042', config.HTTP_URL + '/fwp/puser/puserinfo')

// 单条指派
export const soleAppoint = (params) => Axios(params, 'C0045', config.HTTP_URL + '/fwp/task/assigntask')

// 取消指派开发
export const toCancelAppoint = (params) => Axios(params, 'C0049', config.HTTP_URL + '/fwp/task/cancelassign')

// 批量查询接单方列表
export const toImppusers = (params) => Axios(params, 'C0058', config.HTTP_URL + '/fwp/puser/imppusers')

// 批量查询接单方列表(puid查询)
export const toImppusersDetail = (params) => Axios(params, 'C0051', config.HTTP_URL + '/fwp/puser/imppuser')

// 审核接单方信息(通过/未通过)
export const checkPuser = (params) => Axios(params, 'C0044', config.HTTP_URL + '/fwp/puser/auditpuser')

// 全部结算
export const toSettle = (params) => Axios(params, 'C0048', config.HTTP_URL + '/fwp/task/settle')

// 查询账户余额
export const queryAccount = (params) => Axios(params, 'C0047', config.HTTP_URL + '/fwp/task/calamount')

// 删除接单方信息功能
export const delImppuser = (params) => Axios(params, 'C0061', config.HTTP_URL + '/fwp/puser/delimppuser')

// 提交审核--(接单方列表)
export const auditImppuser = (params) => Axios(params, 'C0060', config.HTTP_URL + '/fwp/puser/auditimppuser')

// 查询系统汇款账号
export const querySysaccount = (params) => Axios(params, 'C0056', config.HTTP_URL + '/fwp/api/sysaccount')

// 查询首页
export const queryIndex = (params) => Axios(params, 'C0055', config.HTTP_URL + '/fwp/api/index')

// 申请开票
export const applyinvoice = (params) => Axios(params, 'C0034', config.HTTP_URL + '/fwp/company/applyinvoice')

// 企业账户充值
export const tradeRecharge = (params) => Axios(params, 'C0024', config.HTTP_URL + '/fwp/trade/recharge', 'POST', { header: { 'Content-Type': 'application/x-www-form-urlencoded' } })

// 审核企业充值信息
export const tradeAuditrecharge = (params) => Axios(params, 'C0062', config.HTTP_URL + '/fwp/trade/auditrecharge')

// 获取小程序码
export const createwxaqrcode = (params) => Axios({}, 'C0062', config.HTTP_URL + '/fwp/wx/createwxaqrcode' + params, 'GET')

// 查询任务接单信息
export const taskapplyinfo = (params) => Axios(params, 'C0066', config.HTTP_URL + '/fwp/task/applyinfo')

// 导出接单信息
export const taskExportpusers = (params) => Axios(params, 'C0067', config.HTTP_URL + '/fwp/task/exportpusers')

// 导出接单方列表信息
export const puserExportpusers = (params) => Axios(params, 'C0068', config.HTTP_URL + '/fwp/puser/exportpusers')

// 下载合同
export const puserContract = (params) => Axios({}, '', config.HTTP_URL + '/fwp/puser/contract' + params, 'GET', { header: { 'Content-Type': '' }, responseType: 'blob', timeout: 0 })

// 查询签约列表
export const taskSignManage = (params) => Axios(params, 'C0069', config.HTTP_URL + '/fwp/task/signManage')

// 通过该接口获取接单方签约流程详情消息
export const getSignDetail = (params) => Axios(params, 'C0070', config.HTTP_URL + '/fwp/sign/getSignDetail')

// 通过该接口导出企业接单方签约消息列表
export const exportsign = (params) => Axios(params, 'C0071', config.HTTP_URL + '/fwp/task/exportsign')

// 文件下载 file_type 1-批量导入接单方模板文件 2-批量导入接单方失败文件 3-批量指派任务模板文件 4-批量指派任务失败文件 5-交易接单信息导出文件
export const downloadfile = (params) => Axios({}, '', config.HTTP_URL + '/fwp/api/downloadfile' + params, 'GET', { header: { 'Content-Type': '' }, responseType: 'blob', timeout: 0 })

/**
 * 组织管理
 */

// 查询企业组织架构信息列表 & 搜索/查看详情企业组织架构信息
export const cropFrameworklist = (params) => Axios(params, 'C0072', config.HTTP_URL + '/fwp/crop/frameworklist')

// 新增/修改企业组织架构信息
export const cropEditframework = (params) => Axios(params, 'C0074', config.HTTP_URL + '/fwp/crop/editframework')

// 通过该接口新增/修改企业组织架构信息
export const cropDelframework = (params) => Axios(params, 'C0073', config.HTTP_URL + '/fwp/crop/delframework')
